
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {
  },
  computed: {
  }
})
export default class Step extends Vue {

  @Prop({ type: Number, default: 0 }) readonly step !: number;
  @Prop({ type: Boolean, default: false }) readonly isXiu !: boolean;




  created(){
    const _this:any = this;
  }
  mounted(){
    const _this:any = this;
  }



}

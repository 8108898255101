
import {Component, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
import {GetByPageCustomizationReq} from "@/interface/res";
import {GetByPageCustomizationApi} from "@/network/modules";
@Component({
  components: {
  },
  computed: {
  }
})
export default class MainList extends Vue {

  // @Prop({ type: Array, default: [] }) readonly menuList ?: Array<any>;
  @Prop({ type: Boolean, default: false }) readonly show !: string;
  @Prop({ type: Object, default: ()=>{} }) readonly params !: any;


  @Provide() dialogVisible: boolean = false
  @Provide() loading: boolean = false
  @Provide() menuIndex: number = 0

  @Provide() condition:GetByPageCustomizationReq = {
    pageNum:1,
    pageSize:8,
    galleryType:3
  }
  @Provide() list: Array<any> = []
  @Provide() listTotal: number = 0
  @Provide() listIndex: number = -1
  @Provide() item: any = {}


  @Watch('show',{immediate:true, deep:false})
  private iValChange(newVal:number):void {
    const _this:any = this
    _this.dialogVisible = _this.show
    if(_this.$base.isNull(newVal))return;

    if(_this.$base.isNull(_this.params)){
      _this.GetListFn()
      return
    }
    if(_this.params.id <= -1){
      _this.listIndex = -1
      _this.condition.pageNum = 1
    }
    else{
      _this.condition.pageNum = _this.params.pageNum
    }
    _this.GetListFn()
  }

  created(){
    const _this:any = this;
  }
  mounted(){
    const _this:any = this;
  }
  /**
   * @Author HS
   * @Date 2021/8/13 11:23 上午
   * @Description: 选择产品
   * @Params: { number ： index - true } [下标]
   * @Return: null
   */
  listChange(index:number){
    const _this:any = this;
    // if(index == _this.listIndex){
    //   _this.listIndex = -1
    //   return
    // }
    _this.listIndex = index
    _this.params.id = _this.list[_this.listIndex].galleryId
    _this.params.pageNum = _this.condition.pageNum
    _this.item = _this.list[_this.listIndex]
  }
  /**
   * @Author HS
   * @Date 2021/8/13 11:23 上午
   * @Description: 切换类型
   * @Params: { number ： index - true } [下标]
   * @Return: null
  */
  menuChange(index:number){
    const _this:any = this;
    if(index == 0){
      delete _this.condition.galleryStyleType
    }
    else{
      _this.condition.galleryStyleType = _this.menuList[index].dictValue
    }
    _this.condition.pageNum = 1
    _this.menuIndex = index
    _this.listIndex = -1

    _this.GetListFn()
  }
  /**
   * @Author HS
   * @Date 2021/8/13 11:17 上午
   * @Description: 切换页码
   * @Params: { number ： val - true } [页码]
   * @Return:
  */
  pageChange(val:number){
    const _this:any = this;
    _this.condition.pageNum = val
    _this.GetListFn()
  }

  /**
   * @Author HS
   * @Date 2021/8/12 6:27 下午
   * @Description: 辅单元产品列表
   * @Params: null
   * @Return: null
   */
  async GetListFn(){
    const _this:any = this;
    _this.loading = true
    if(_this.condition.pageNum == 1){
      _this.listTotal = -1
      _this.list = []
    }
    let params:GetByPageCustomizationReq = _this.condition
    const res = await GetByPageCustomizationApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { data, code, total, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.list = [...data]
    _this.listTotal = total

    if(_this.params.id > -1){
      _this.listIndex = _this.list.findIndex((item:any) => item.galleryId == _this.params.id)
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/15 11:52 上午
   * @Description: 数据切换回调
   * @Params: null
   * @Return: null
  */

  dataCallbackFn(){
    const _this:any = this
    let params = {
      pageNum : _this.params.pageNum,
      id:-1
    }

    if(_this.$base.isNull(_this.item)){
      _this.$message.error("请选择辅单元");
      // _this.$emit('confirmCallback',{},params)
      return;
    }
    params.id = _this.item.galleryId
    _this.$emit('confirmCallback',_this.item,params)
  }



}

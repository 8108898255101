export function Drag(el:any, bindings:any, vnode:any) {
  const self:any = vnode.context;
  let dragging = false;
  el.onmousedown = function (eli:any) {
    eli = eli || window.event;
    let tar = eli.target
    let move = tar.nodeName == "DIV" || tar.nodeName  == 'IMG' ;
    let div:any = document.querySelector('.imgdiy'+self.menuIndex)
    let diyImg:any = document.querySelector('.diy-img')
    if(self.$base.isNull(div))return
    if(self.$base.isNull(diyImg))return
    let multiple = diyImg.getAttribute('style').match(/\d+(.\d+)?/g)[0]
    dragging = true
    let main:any = document.querySelector(".imgdiy0");
    let isOverturn:number = 1
    if(main.style.transform.indexOf('rotateY(180deg)') > -1 && self.menuIndex != 0){
      isOverturn = -isOverturn
    }
    document.onmousemove = function (event:any) {
      event = event || window.event;
      event.preventDefault();

      if (dragging) {
        let top = parseInt(div.style.top),
          left = parseInt(div.style.left);
        if(move){
          div.style.top = (top + (event.movementY / multiple))  + 'px';
          div.style.left = (left + (event.movementX / multiple * isOverturn)) + 'px';
          if(self.menuIndex != 0){
            self.elementBorder.style.top = (top + (event.movementY / multiple))  + 'px';
            self.elementBorder.style.left = (left + (event.movementX / multiple * isOverturn)) + 'px';
          }
        }
        else {
          let rate = div.getAttribute('rate');
          let img = div.querySelector('img');
          let width = img.width;
          let imgWidth = 0,imgHeight = 0;
          if(tar.className == 'tLeft' || tar.className == 'bLeft'){
            if(parseFloat(self.elementBorder.style.width) <= self.minNum && event.movementX > 0){
              div.style.width = self.elementBorder.style.width = img.style.width = self.minNum + 'px'
              div.style.height = self.elementBorder.style.height = img.style.height = self.minNum / rate + 'px'
              return
            }
            if(parseFloat(self.elementBorder.style.height) <= self.minNum && event.movementX > 0){
              div.style.height = self.elementBorder.style.height = img.style.height = self.minNum + 'px'
              div.style.width = self.elementBorder.style.width = img.style.width = self.minNum * rate + 'px'
              return
            }
            imgWidth = width - (event.movementX / multiple)

          }
          else if(tar.className == 'tRight' || tar.className == 'bRight'){
            if(parseFloat(self.elementBorder.style.width) <= self.minNum && event.movementX < 0){
              div.style.width = self.elementBorder.style.width = img.style.width = self.minNum + 'px'
              div.style.height = self.elementBorder.style.height = img.style.height = self.minNum / rate + 'px'
              return
            }
            if(parseFloat(self.elementBorder.style.height) <= self.minNum && event.movementX < 0){
              div.style.height = self.elementBorder.style.height = img.style.height = self.minNum + 'px'
              div.style.width = self.elementBorder.style.width = img.style.width = self.minNum * rate + 'px'
              return
            }
            imgWidth = width + (event.movementX / multiple)

          }

          imgHeight = imgWidth / rate
          div.style.width = self.elementBorder.style.width = img.style.width = imgWidth + 'px'
          div.style.height = self.elementBorder.style.height = img.style.height = imgHeight + 'px'


        }


      }
    }
    document.onmouseup = function () {
      dragging = false;
      document.onmousemove = document.onmouseup = null;
    }
  }
}

